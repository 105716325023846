import React from "react";
import Header from "../components/Header";
import { GoDotFill } from "react-icons/go";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import SectionTItle from "../components/SectionTitle";

const JoinPage = () => {
  return (
    <div className="JoinPage">
      <Header currentPage="join" />

      <div className="relative h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        <img className="absolute inset-0 w-full h-full object-cover opacity-25" src="./images/yfc-join.jpg" alt="" />
        <div className="z-30 container mx-auto space-y-2">
          <h2 className="text-sm lg:text-base">Are you ready ?</h2>
          <h1 className="text-4xl lg:text-6xl">Join us</h1>
        </div>
        <NavLink to="/live" className="z-30 btn bg-gray-100 hover:bg-red-700 text-sm lg:text-base text-gray-900 hover:text-white flex items-center">
          Live
          <GoDotFill className="ml-0.5 text-red-700" />
        </NavLink>
      </div>

      <div className="my-20 space-y-20">
        <div className="container mx-auto px-4">
          <SectionTItle title="Locations" />
          <div className="space-y-16">
            <div className="grid grid-cols-2 gap-12">
              <div className="shadow-lg rounded-lg grid grid-cols-2">
                <img className="w-full h-full object-cover" src="./images/yfc-tana.jpg" alt="" />
                <div className="px-5 py-3">
                  <p className="text-gray-700 text-lg">
                    Rex Andohan'analakely<br />
                    Antananarivo<br />
                    101
                  </p>
                </div>
              </div>
              <div className="shadow-lg rounded-lg grid grid-cols-2">
                <img className="w-full h-full object-cover" src="./images/yfc-antsirabe.jpg" alt="" />
                <div className="px-5 py-3">
                  <p className="text-gray-700 text-lg">
                    CCIA Antsirabe<br />
                    Antsirabe<br />
                    110
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
};

export default JoinPage;
