import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFoundPage from './pages/NotFoundPage';
import AboutPage from './pages/AboutPage';
import EventPage from './pages/EventPage';
import ContactPage from './pages/ContactPage';
import CHristPage from './pages/ChristPage';
import GivePage from './pages/GivePage';
import JoinPage from './pages/JoinPage';
import LivePage from './pages/LivePage';
import MaintenancePage from './pages/MaintenancePage';

const App = () => {

  const calculateTimeLeft = () => {
    const year = 2024;
    const month = 7;
    const day = 17;
    const hour = 16;
    const minutes = 0;
    const seconds = 0;
    const targetDate = new Date(year, month, day, hour, minutes, seconds);
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

      if (timeLeft.days < 10) timeLeft.days = `0${timeLeft.days}`
      if (timeLeft.hours < 10) timeLeft.hours = `0${timeLeft.hours}`
      if (timeLeft.minutes < 10) timeLeft.minutes = `0${timeLeft.minutes}`
      if (timeLeft.seconds < 10) timeLeft.seconds = `0${timeLeft.seconds}`
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [onMaintenance, setOnMaintenance] = useState((!timeLeft.days && !timeLeft.hours && !timeLeft.minutes && !timeLeft.seconds) ? false : true);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
      !timeLeft.days && !timeLeft.hours && !timeLeft.minutes && !timeLeft.seconds && setOnMaintenance(false);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <BrowserRouter>
      <Routes>
        {onMaintenance && process.env.NODE_ENV === 'production' ? (
          <Route path="/" element={<MaintenancePage timeLeft={timeLeft} />} />
        ) : (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/event" element={<EventPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/christ" element={<CHristPage />} />
            <Route path="/give" element={<GivePage />} />
            <Route path="/join" element={<JoinPage />} />
            <Route path="/live" element={<LivePage />} />
          </>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
