import React, { useState } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from '../Utils';

const Header = ({ currentPage }) => {
  const [backgroundColor, setBackgroundColor] = useState("bg-transparent");

  const navigation = [
    { name: 'Home', href: '/', current: currentPage === "home" ? true : false },
    { name: 'About', href: '/about', current: currentPage === "about" ? true : false },
    { name: 'Event', href: '/event', current: currentPage === "event" ? true : false },
    // { name: 'Contact', href: '/contact', current: currentPage === "contact" ? true : false },
    { name: 'Christ', href: '/christ', current: currentPage === "christ" ? true : false },
  ]

  const handleChangeBackground = () => {
    window.scrollY >= 50 ? setBackgroundColor("bg-dark shadow-xl") : setBackgroundColor("bg-transparent");
}

  window.addEventListener('scroll', handleChangeBackground);

  return (
    <>
      <header className={classNames(backgroundColor, "Header w-full fixed z-50 py-4 transition ease-linear duration-200")}>
        <nav className="container mx-auto w-full px-4">
          <Disclosure>
            <div className="flex">
              <div className="flex justify-between lg:grid grid-cols-12 w-full">
                <div className="col-span-2 flex">
                  <a className="opacity-85 hover:opacity-100 transition ease-linear duration-100" href="/">
                    <img className="w-40" src="./images/yfc-logo.png" alt="YFC" />
                  </a>
                </div>
                <div className="flex lg:hidden">
                  <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none focus:ring-0">
                    <span className="absolute -inset-0.5" />
                    <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                    <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                  </DisclosureButton>
                </div>
                <div className="hidden lg:flex col-span-8 justify-center items-center">
                  <div className="font-bold uppercase text-sm space-x-8">
                    {navigation.map((item) => (
                      <a
                        className={classNames(
                          item.current ? "text-primary" : "text-gray-200",
                          "hover:text-primary transition ease-linear duration-100",
                        )}
                        href={item.href}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="hidden lg:flex col-span-2 justify-end items-center">
                  <div className="text-gray-100 font-bold uppercase text-sm space-x-4">
                    {/* <a href="/give" className="btn bg-gray-700 hover:bg-gray-800">Give</a> */}
                    <a href="/join" className="btn bg-primary hover:bg-red-700">Join</a>
                  </div>
                </div>
              </div>
            </div>
            <DisclosurePanel className="sm:hidden">
              <div className="px-2 pb-3 pt-2 text-center space-y-2">
                <div className="space-y-1">
                  {navigation.map((item) => (
                    <DisclosureButton
                      key={item.name}
                      as="a"
                      href={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current && "bg-primary",
                        "block rounded-md px-3 py-2 text-base font-medium text-gray-200",
                      )}
                    >
                      {item.name}
                    </DisclosureButton>
                  ))}
                </div>
                <div className="text-gray-100 font-bold uppercase text-sm flex flex-col space-y-2">
                  {/* <a href="/give" className="btn bg-gray-700 hover:bg-gray-800 w-full">Give</a> */}
                  <a href="/join" className="btn bg-primary hover:bg-red-700 w-full">Join</a>
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </nav>
      </header>
    </>
  )
};

export default Header;
